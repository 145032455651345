<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    label="الدور"
    multiple
    small-chips
    outlined
    :loading="isLoading || loading"
  >
    <!-- options item style -->
    <template #item="{ item }">
      <span> {{ item.text }}</span>
    </template>

    <!-- options style on no data -->
    <template v-slot:no-data v-if="canCreateNew">
      <v-list-item>
        <span class="subheading ml-2">انشاء</span>
        <v-chip color="teal lighten-3" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>

    <!-- selected style -->
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="(item.id != null ? 'cyan' : 'teal') + ' lighten-3'"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2"> {{ item.text }} </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import axiosIns from '@/libs/http/http';
import { makeid } from '@/utls/jsCommon';

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    isLoading: false,
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    items: [
      // {
      //   text: 'item text',
      // },
    ],
    menu: false,
    model: [
      // {
      //   text: 'item text',
      // },
    ],
    x: 0,
    search: null,
    y: 0,
  }),
  computed: {
    canCreateNew() {
      return false;
    },
  },
  methods: {
    getData() {
      // Items have already been loaded
      if (this.items.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      else this.isLoading = true;

      // Lazily load input items
      axiosIns
        .get('identity/roles/all', { params: {} })
        .then((response) => {
          const responseRoles = response.data.items;
          this.items = responseRoles.reduce((p, v) => {
            return [...p, this.createNewItem({ id: v.id, text: v.name })];
          }, []);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    createNewItem({ id, text }) {
      return { id, text };
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : '');
      const isThere = (query, val) =>
        val.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return isThere(query, text);
    },
    getIfIsAlreadyInItems(v) {
      const item = this.items.find(
        (x) => x.text.toLocaleLowerCase() == v.toLocaleLowerCase(),
      );
      return item;
    },
    addNewIfCan(v) {
      if (!this.canCreateNew) return;
      // create new and return it
      const newItem = this.createNewItem({ id: null, text: v });
      // this.items.push(newItem);
      return newItem;
    },
    modifyModel(newValue) {
      this.model = newValue.map((v) => {
        // ------------------------
        // check is item new or already created
        // ------------------------
        if (typeof v === 'string') {
          // check input is there
          // is is return it
          const item = this.getIfIsAlreadyInItems(v);
          if (item) return item;

          const newItem = this.addNewIfCan(v);
          if (newItem) return newItem;
        }
        // ------------------------
        // return already created
        // ------------------------
        return v;
      });
    },
  },
  created() {
    this.getData();
  },
  watch: {
    model(newValue, prev) {
      if (newValue.length === prev.length) return;
      this.modifyModel(newValue);
      
      // notify the v-model
      const allText = this.model.filter((x) => x.text).map((x) => x.text);
      this.$emit('input', allText);
    },
    value() {
      this.modifyModel(this.value);
    },
  },
};
</script>
